<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-03-08 16:53:20
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-07-22 16:09:49
 * @FilePath: \official_website\src\mobile\pages\contact\Contact.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <div id="contact">
    <Header></Header>
    <div class="contact-top">
      <p class="title animate__animated animate__fadeInDown">人才招募</p>
    </div>
    <div class="recruit-box">
      <ul class="recruit">
        <li
          class="recruit-list wow animate__animated animate__fadeInUp"
          v-for="(item, index) in recruitmentList"
          :key="index"
          @click="tapList(item)"
        >
          <div class="title">{{ item.jobName }}</div>
          <div class="department">{{ item.jobType }}</div>
        </li>
      </ul>
      <div class="news-footer">
        <van-button plain color="#0b847b" v-if="moreFlag" @click="knowMore"
          >了解更多</van-button
        >
      </div>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>

    <van-popup
      v-model="jobFlag"
      round
      closeable
      position="bottom"
      :style="{ height: '85%' }"
    >
      <div class="job-detail">
        <div class="header">
          <div class="position">{{ jobInfo.jobName }}</div>
          <div class="department">{{ jobInfo.jobType }}</div>
        </div>
        <div class="responsibility">
          <div class="title">岗位职责</div>
          <p class="post-box" style="white-space: pre-line">
            {{ jobInfo.detail }}
          </p>
        </div>
        <div class="job-request">
          <div class="title">岗位要求</div>
          <p class="job-box" style="white-space: pre-line">
            {{ jobInfo.edu }}
          </p>
        </div>
        <div class="demand-area">
          <div class="title">需求区域</div>
          <div class="demand-box">
            <ul class="demand">
              <li
                class="demand-list"
                v-if="jobInfo.area1 && jobInfo.number1 && jobInfo.provice1"
              >
                <div class="left">
                  <span class="area">{{ jobInfo.area1 }}</span>
                  <span class="number">{{ jobInfo.number1 }}人</span>
                </div>
                <div class="right">{{ jobInfo.provice1 }}</div>
              </li>
              <li
                class="demand-list"
                v-if="jobInfo.area2 && jobInfo.number2 && jobInfo.provice2"
              >
                <div class="left">
                  <span class="area">{{ jobInfo.area2 }}</span>
                  <span class="number">{{ jobInfo.number2 }}人</span>
                </div>
                <div class="right">{{ jobInfo.provice2 }}</div>
              </li>
              <li
                class="demand-list"
                v-if="jobInfo.area3 && jobInfo.number3 && jobInfo.provice3"
              >
                <div class="left">
                  <span class="area">{{ jobInfo.area3 }}</span>
                  <span class="number">{{ jobInfo.number3 }}人</span>
                </div>
                <div class="right">{{ jobInfo.provice3 }}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
    
<script>
import api from "../../../api/api";
import Header from "../../components/Header.vue";
import Footer from "../../components/Footer.vue";
export default {
  name: "",
  props: {},
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      recruitmentList: [],
      count: null,
      currentPage: 1,
      moreFlag: true,
      jobFlag: false,
      jobInfo: {},
    };
  },
  created() {
    this.getRecruitLists();
    this.getRecruitCounts();
  },
  mounted() {
    this.$nextTick(() => {
      new this.$wow.WOW({
        boxClass: "wow", // 动画元件css类（默认为wow）
        animateClass: "animate__animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      }).init();
    });
  },
  watch: {
    currentPage: {
      handler(oldValue, newValue) {
        // console.log(newValue);
        setTimeout(() => {
          if (this.currentPage >= Math.ceil(this.count / 10)) {
            this.moreFlag = false;
          }
        }, 300);
      },
      deep: true, // 深度观察监听 设置为 true
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
  methods: {
    // 获取招聘分类列表
    getRecruitLists(page = 1) {
      api.getRecruitByPage(page).then((res) => {
        let result = res.data.models;
        // console.log(result);
        result.forEach((item) => {
          if (item.remark) {
            item.demandArea = JSON.parse(item.remark);
            item.area1 = item.demandArea[0].area1;
            item.number1 = item.demandArea[0].number1;
            item.provice1 = item.demandArea[0].provice1;
            item.area2 = item.demandArea[1].area2;
            item.number2 = item.demandArea[1].number2;
            item.provice2 = item.demandArea[1].provice2;
            item.area3 = item.demandArea[2].area3;
            item.number3 = item.demandArea[2].number3;
            item.provice3 = item.demandArea[2].provice3;
          } else {
            item.demandArea = [];
          }

          item.foldFlag = false;
        });

        if (page > 1) {
          this.recruitmentList = this.recruitmentList.concat(result);
        } else {
          this.recruitmentList = result;
        }

        // console.log(this.recruitmentList);

        // this.$nextTick(() => {
        //   new this.$wow.WOW({
        //     boxClass: "wow", // 动画元件css类（默认为wow）
        //     animateClass: "animate__animated", //动画css类（默认为animated）
        //     offset: 0, //到元素距离触发动画（当默认为0）
        //     mobile: true, //在移动设备上触发动画（默认为true）
        //     live: true, //对异步加载的内容进行操作（默认为true）
        //   }).init();
        // });
      });
    },
    // 获取招聘总条数
    getRecruitCounts() {
      api
        .getRecruitCount()
        .then((res) => {
          this.count = res.data.models[0];
        })
        .catch((error) => {});
    },
    knowMore() {
      if (this.currentPage < Math.ceil(this.count / 10)) {
        this.currentPage++;
        this.getRecruitLists(this.currentPage);
      }
    },
    tapList(item) {
      this.jobFlag = true;
      this.jobInfo = item;
    },
  },
};
</script>
    
<style lang="scss">
#contact {
  padding-top: 3.75rem;
  .contact-top {
    position: relative;
    width: 100%;
    height: 12rem;
    background-image: url("../../../assets/image/contact/zhpin.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    margin-bottom: 1rem;
    .title {
      position: absolute;
      left: 2rem;
      top: 35%;
      transform: translateY(-50%);
      color: #fff;
      font-size: 1.5rem;
      font-family: "SourceHanSansSC-Medium";
    }
  }
  .recruit-box {
    padding: 0 1.25rem;
    .recruit {
      width: 100%;
      .recruit-list {
        width: 100%;
        padding: 1rem 0;
        border-bottom: 0.0625rem solid #dcdddd;
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        .title {
          font-size: 1.2rem;
          font-family: "SourceHanSansSC-Medium";
          margin-bottom: 0.8rem;
        }
        .department {
          display: inline-block;
          padding: 0.3rem 0.625rem;
          background-color: #e6e6e6;
          color: #666;
          font-size: 1rem;
          font-family: "OPlusSans3-Medium";
        }
      }
    }
    .news-footer {
      display: flex;
      justify-content: center;
      padding: 1rem 0 2rem;
    }
  }

  .job-detail {
    position: relative;
    padding: 1.25rem;
    background: #f5f5f5;
    border-radius: 0.625rem;
    // margin-bottom: 1.5rem;
    overflow: hidden;
    .header {
      // display: flex;
      // justify-content: space-between;
      margin-bottom: 1.2rem;
      .position {
        font-size: 1.4rem;
        font-family: "SourceHanSansSC-Medium";
        margin-bottom: 0.6rem;
      }
      .department {
        display: inline-block;
        padding: 0.3rem 0.625rem;
        background-color: #e6e6e6;
        color: #666;
        font-size: 1rem;
        font-family: "OPlusSans3-Medium";
      }
    }
    .responsibility {
      margin-bottom: 1.2rem;
      .title {
        font-size: 1.2rem;
        font-family: "SourceHanSansSC-Medium";
        margin-bottom: 1rem;
      }
      .post-box {
        font-size: 0.9rem;
        font-family: "OPlusSans3-Medium";
        line-height: 2rem;
        color: #666;
      }
    }
    .job-request {
      margin-bottom: 1.2rem;
      .title {
        font-size: 1.2rem;
        font-family: "SourceHanSansSC-Medium";
        margin-bottom: 1rem;
      }
      .job-box {
        font-size: 0.9rem;
        font-family: "OPlusSans3-Medium";
        line-height: 2rem;
        color: #666;
      }
    }
    .demand-area {
      margin-bottom: 1.2rem;
      .title {
        font-size: 1.2rem;
        font-family: "SourceHanSansSC-Medium";
        margin-bottom: 1rem;
      }
      .demand-box {
        font-size: 0.9rem;
        font-family: "OPlusSans3-Medium";
        .demand {
          .demand-list {
            padding: 0.5rem 0.5rem;
            background: #dde9e8;
            border-radius: 0.4rem;
            display: flex;
            align-items: center;
            margin-bottom: 0.8rem;
            .left {
              margin-right: 0.5rem;
              span {
                display: inline-block;
              }
              .area {
                font-size: 0.875rem;
                font-family: "SourceHanSansSC-Medium";
              }
              .number {
                margin: 0 0.5rem;
                color: #0b847b;
              }
            }
            .right {
              color: #666;
            }
          }
        }
      }
    }
    .buttons {
      position: absolute;
      right: 2rem;
      bottom: 1rem;
      display: flex;
      justify-content: flex-end;
      color: #0b847b;
      font-size: 0.9rem;
      font-family: "OPlusSans3-Medium";
      span {
        cursor: pointer;
      }
    }
  }
  .van-popup__close-icon {
    position: fixed;
    top: 17%;
    color: #0b847b;
  }
  .van-popup--bottom {
    background: #f5f5f5;
  }
  .footer {
    background: #f5f5f5;
  }
}
</style>
